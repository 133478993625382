import React from "react"
import { CustomLogo } from "../components/Headers/CustomLogo"
import { RedHalvaWithSovcombank } from "../components/Headers/Logos"
import Layout from "../components/Layouts/secondaryPage"
import Success from "../components/Success"

const title = "Спасибо"
const subTitle = "Ваша заявка принята"
const description =
  "После получения карты «Халва» промокод появится в личном кабинете в течение семи дней."

export default function Page() {
  return (
    <Layout>
      <CustomLogo withButton={false} Logo={RedHalvaWithSovcombank} />
      <Success title={title} subTitle={subTitle} description={description} />
    </Layout>
  )
}
